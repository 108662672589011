<template>
  <div>
    <vs-row class="items-start">
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="12"
        class="pr-5"
      >
        <vx-card class="custom-vx-card">
          <vs-table :data="transactions" >
              <template slot="header">
                <vs-select class="flex justify-end w-full items-center py-4 mr-4 record-select"  label="Show records" v-model="limit" >
                  <vs-select-item
                    :key="index"
                    :value="item.value"
                    :text="item.text"
                    v-for="(item, index) in limitValue"
                  />
                </vs-select>
              </template>
            <template slot="thead">
              <vs-th> Date </vs-th>
              <vs-th> Your reference </vs-th>
              <vs-th> Transaction ID </vs-th>
              <vs-th> Amount </vs-th>
              <vs-th> Customer </vs-th>
              <vs-th> Status </vs-th>
              <vs-th> </vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="tr.createdAt">
                  {{ moment(tr.createdAt) }}
                </vs-td>
                <vs-td :data="tr.reference">
                  {{ tr.reference }}
                </vs-td>
                <vs-td :data="tr.transactionID">
                  {{ tr.transactionID }}
                </vs-td>
                <vs-td :data="tr.amount">
                  {{ formatCurrency(tr.amount) }}
                </vs-td>
                <vs-td
                  :data="tr.paymentRequest ? tr.paymentRequest.payeeName : ''"
                >
                  {{ tr.paymentRequest ? tr.paymentRequest.payeeName : "" }}
                </vs-td>
                <vs-td :data="tr.paymentStatus">
                  {{
                    tr.paymentStatus === "Refunded"
                      ? tr.refundedByUser.length > 0
                        ? "Refunded By " + tr.refundedByUser[0].fullName
                        : "Refunded"
                      : tr.paymentStatus
                  }}
                </vs-td>
                <vs-td :data="tr">
                  <a
                    @click="viewDetail(tr)"
                    >View</a
                  >
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <div>
            <vs-pagination
              :total="totalPage"
              v-model="pageNumber"
            ></vs-pagination>
          </div>
        </vx-card>
      </vs-col>
    </vs-row>
    <!-- Transaction Detail PopUp -->
    <vs-popup
      class="holamundo"
      :title="''"
      :active.sync="transactionDetailPopup"
    >
      <h3 class="font-bold">Transaction details</h3>
      <vs-row class="mt-10">
        <vs-col vs-w="12">
          <div class="flex mb-5">
            <label for="payeeName" class="w-1/2">Payee name</label>
            <span class="font-bold w-1/2">
              {{
                selectedTransaction.payeeName
                  ? selectedTransaction.payeeName
                  : ""
              }}
            </span>
          </div>
          <div class="flex mb-5">
            <label class="w-1/2" for="payeePhone">Payee phone</label>
            <span class="font-bold w-1/2">
              {{
                selectedTransaction.payeePhone
                  ? selectedTransaction.payeePhone
                  : ""
              }}
            </span>
          </div>
          <div class="flex mb-5">
            <label class="w-1/2" for="payeeEmail">Payee email</label>
            <span class="font-bold w-1/2">
              {{
                selectedTransaction.payeeEmail
                  ? selectedTransaction.payeeEmail
                  : ""
              }}
            </span>
          </div>
          <div class="flex mb-5">
            <label class="w-1/2" for="amount">Amount</label>
            <span class="font-bold w-1/2">
              {{
                selectedTransaction.amount
                  ? "$" + selectedTransaction.amount
                  : ""
              }}
            </span>
          </div>
          <div class="flex mb-5">
            <label class="w-1/2" for="yourReference">Your reference</label>
            <span class="font-bold w-1/2">
              {{
                selectedTransaction.reference
                  ? selectedTransaction.reference
                  : ""
              }}
            </span>
          </div>
          <div class="flex mb-5">
            <label class="w-1/2" for="paymentPage">Payment Page</label>
            <span class="font-bold w-1/2">
              {{
                selectedTransaction.page ? selectedTransaction.page.title : ""
              }}
            </span>
          </div>
          <div class="flex mb-5">
            <label class="w-1/2" for="txConfirm">TX confirm</label>
            <span class="font-bold w-1/2">
              {{
                selectedTransaction.paymentStatus
                  ? selectedTransaction.paymentStatus
                  : ""
              }}
            </span>
          </div>
          <div class="flex mb-5">
            <label class="w-1/2" for="txReference">TX reference</label>
            <span class="font-bold w-1/2"> Reference </span>
          </div>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      transactionDetailPopup: false,
      selectedTransaction: "",
      pageNumber: 1,
      totalData: 0,
      limit: 50,
      transactions: [],
          limitValue: [
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],
    };
  },
  methods: {
    ...mapActions("transaction", ["fetchTransactionListByPartnerId"]),
    async getListSetting() {
      const payload = {
        name: this.$route.name,
        partnerId: JSON.parse(localStorage.getItem("user"))._id,
      };
      await this.$store
        .dispatch("listSetting/updatelistSetting", payload)
        .then((res) => {
          this.limit = parseInt(res.data.data.limit) || this.limit;
        })
        .catch((err) => {

        });
    },
    async saveListSetting(limit) {
      const payload = {
        name: this.$route.name,
        limit,
        partnerId: JSON.parse(localStorage.getItem("user"))._id,
      };
      await this.$store
        .dispatch("listSetting/updatelistSetting", payload)
        .then((res) => {})
        .catch((err) => {

        });
    },
    moment(date) {
      return moment(date).format("DD-MMM-YYYY");
    },
    momentTime(date) {
      return moment(date).format("h:mm:ss");
    },
    async getTransactionList(partnerId,page,limit) {
      await this.fetchTransactionListByPartnerId(
        `${partnerId}?page=${page}&limit=${limit}&sort=desc`
      )
        .then((result) => {
          this.transactions = result.data.data.docs;
          this.totalData = result.data.data.pagination.total
            ? result.data.data.pagination.total
            : 0;
        })
        .catch((err) => {});
    },
    moneyFormat(data) {
      return `$ ${parseFloat(data)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
    },
    showMessage(title, message, color, icon) {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon,
      });
    },
    viewDetail(data) {
      this.$router.push({
        name: "staff-transactions-details",
        params: { id: data.paymentRequestId },
      });
    },
  },
  mounted() {
    this.getListSetting();
    this.getTransactionList(this.partnerId, this.pageNumber, this.limit);
  },
  watch: {
    pageNumber(val) {
      this.getTransactionList(this.partnerId, val, this.limit);
    },
    limit(val) {
      this.getTransactionList(this.partnerId, 1, val);
      this.saveListSetting(val);
    },
  },
  computed: {
    user() {
      return this.$store.state.AppActiveUser;
    },
    partnerId() {
      return this.user.userType === "admin"
        ? this.user._id
        : this.user.partnerId
    },
    totalPage: function () {
      return Math.ceil(this.totalData / this.limit);
    },
  },
};
</script>
